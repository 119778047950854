import ActiveCheckmarkDark from './Icons/ActiveCheckmarkDark.svg';
import AdviceDark from './Icons/AdviceDark.svg';
import AdviceLight from './Icons/AdviceLight.svg';
import ArrowLeftDark from './Icons/ArrowLeftDark.svg';
import ArrowLeftLight from './Icons/ArrowLeftLight.svg';
import ArrowRightDark from './Icons/ArrowRightDark.svg';
import ArrowRightLight from './Icons/ArrowRightLight.svg';
import BasketDark from './Icons/BasketDark.svg';
import BasketLight from './Icons/BasketLight.svg';
import BlockedDark from './Icons/BlockedDark.svg';
import BlockedLight from './Icons/BlockedLight.svg';
import BoxDark from './Icons/BoxDark.svg';
import BoxLight from './Icons/BoxLight.svg';
import CalendarDark from './Icons/CalendarDark.svg';
import CalendarLight from './Icons/CalendarLight.svg';
import CardAmex from './Icons/CardAmex.svg';
import CardMastercard from './Icons/CardMastercard.svg';
import CardVisa from './Icons/CardVisa.svg';
import ChevronDownDark from './Icons/ChevronDownDark.svg';
import ChevronDownLight from './Icons/ChevronDownLight.svg';
import ChevronLeftDark from './Icons/ChevronLeftDark.svg';
import ChevronLeftLight from './Icons/ChevronLeftLight.svg';
import ChevronRightDark from './Icons/ChevronRightDark.svg';
import ChevronRightLight from './Icons/ChevronRightLight.svg';
import ChevronRightSecondary from './Icons/ChevronRightSecondary.svg';
import CircleCrossDark from './Icons/CircleCrossDark.svg';
import CircleCrossLight from './Icons/CircleCrossLight.svg';
import CirclePlusDark from './Icons/CirclePlusDark.svg';
import CirclePlusLight from './Icons/CirclePlusLight.svg';
import CheckmarkThinDark from './Icons/CheckmarkThinDark.svg';
import CheckmarkThinLight from './Icons/CheckmarkThinLight.svg';
import CheckmarkThinGreen from './Icons/CheckmarkThinGreen.svg';
import CheckmarkDark from './Icons/CheckmarkDark.svg';
import CheckmarkLight from './Icons/CheckmarkLight.svg';
import ClipboardDark from './Icons/ClipboardDark.svg';
import ClipboardLight from './Icons/ClipboardLight.svg';
import ClockDark from './Icons/ClockDark.svg';
import ClockLight from './Icons/ClockLight.svg';
import CrossDark from './Icons/CrossDark.svg';
import CrossLight from './Icons/CrossLight.svg';
import DoctorDark from './Icons/DoctorDark.svg'
import DoctorLight from './Icons/DoctorLight.svg'
import DownloadDark from './Icons/DownloadDark.svg'
import DownloadLight from './Icons/DownloadLight.svg'
import EditLight from './Icons/EditLight.svg';
import EditDark from './Icons/EditDark.svg';
import ErrorStatus from './Icons/ErrorStatus.svg';
import ExitDark from './Icons/ExitDark.svg';
import ExitLight from './Icons/ExitLight.svg';
import EyeClosedDark from './Icons/EyeClosedDark.svg';
import EyeClosedLight from './Icons/EyeClosedLight.svg';
import EyeOpenDark from './Icons/EyeOpenDark.svg';
import EyeOpenLight from './Icons/EyeOpenLight.svg';
import FacebookInfo from './Icons/FacebookInfo.svg';
import FacebookDark from './Icons/FacebookDark.svg';
import FacebookLight from './Icons/FacebookLight.svg';
import HomeDark from './Icons/HomeDark.svg';
import HomeLight from './Icons/HomeLight.svg';
import InfoLight from './Icons/InfoLight.svg';
import InfoDark from './Icons/InfoDark.svg';
import InfoStatus from './Icons/InfoStatus.svg';
import Instagram from './Icons/Instagram.svg';
import LinkedinInfo from './Icons/LinkedinInfo.svg';
import LinkedinLight from './Icons/LinkedinLight.svg';
import LinkedinDark from './Icons/LinkedinDark.svg';
import Loading from './Icons/Loading.svg';
import LockDark from './Icons/LockDark.svg';
import LockLight from './Icons/LockLight.svg';
import MedicalCrossDark from './Icons/MedicalCrossDark.svg';
import MedicalCrossLight from './Icons/MedicalCrossLight.svg';
import MedicineDark from './Icons/MedicineDark.svg';
import MedicineLight from './Icons/MedicineLight.svg';
import MenuDark from './Icons/MenuDark.svg';
import MenuLight from './Icons/MenuLight.svg';
import PaperPlaneDark from './Icons/PaperPlaneDark.svg';
import PaperPlaneLight from './Icons/PaperPlaneLight.svg';
import PaymentMethodsDark from './Icons/PaymentMethodsDark.svg';
import PaymentMethodsLight from './Icons/PaymentMethodsLight.svg';
import PhoneDark from './Icons/PhoneDark.svg';
import PhoneLight from './Icons/PhoneLight.svg';
import PillDark from './Icons/PillDark.svg';
import PillLight from './Icons/PillLight.svg';
import PinDark from './Icons/PinDark.svg';
import PinLight from './Icons/PinLight.svg';
import PlayDark from './Icons/PlayDark.svg';
import PlayLight from './Icons/PlayLight.svg';
import ProfileDark from './Icons/ProfileDark.svg';
import ProfileLight from './Icons/ProfileLight.svg';
import QuoteLight from './Icons/QuoteLight.svg';
import RosetteDark from './Icons/RosetteDark.svg';
import RosetteLight from './Icons/RosetteLight.svg';
import SearchDark from './Icons/SearchDark.svg';
import SearchLight from './Icons/SearchLight.svg';
import SortedDefaultDark from './Icons/SortedDefaultDark.svg';
import SortedDefaultLight from './Icons/SortedDefaultLight.svg';
import SortDark from './Icons/SortDark.svg';
import SortLight from './Icons/SortLight.svg';
import SpeechDark from './Icons/SpeechDark.svg';
import SpeechLight from './Icons/SpeechLight.svg';
import StampDark from './Icons/StampDark.svg';
import StampLight from './Icons/StampLight.svg';
import StarBadgeDark from './Icons/StarBadgeDark.svg';
import StarBadgeLight from './Icons/StarBadgeLight.svg';
import StarEmptyDark from './Icons/StarEmptyDark.svg';
import StarEmptyLight from './Icons/StarEmptyLight.svg';
import StarFullDark from './Icons/StarFullDark.svg';
import StarFullLight from './Icons/StarFullLight.svg';
import StarHalfDark from './Icons/StarHalfDark.svg';
import StarHalfLight from './Icons/StarHalfLight.svg';
import HeartDark from './Icons/HeartDark.svg'
import HeartLight from './Icons/HeartLight.svg'
import HelpDark from './Icons/HelpDark.svg'
import HelpLight from './Icons/HelpLight.svg'
import SuccessDark from './Icons/SuccessDark.svg';
import SuccessLight from './Icons/SuccessLight.svg';
import SuccessStatus from './Icons/SuccessStatus.svg';
import TagDark from './Icons/TagDark.svg';
import TagLight from './Icons/TagLight.svg';
import TrashDark from './Icons/TrashDark.svg';
import TrashLight from './Icons/TrashLight.svg';
import TruckLight from './Icons/TruckLight.svg';
import TruckDark from './Icons/TruckDark.svg';
import WarningDark from './Icons/WarningDark.svg';
import WarningLight from './Icons/WarningLight.svg';
import WarningStatus from './Icons/WarningStatus.svg';
import XInfo from './Icons/XInfo.svg';
import XDark from './Icons/XDark.svg';
import XLight from './Icons/XLight.svg';

export const icons = {
    'active-checkmark-dark': ActiveCheckmarkDark,
    'advice-dark': AdviceDark,
    'advice-light': AdviceLight,
    'arrow-left-dark': ArrowLeftDark,
    'arrow-left-light': ArrowLeftLight,
    'arrow-right-dark': ArrowRightDark,
    'arrow-right-light': ArrowRightLight,
    'basket-dark': BasketDark,
    'basket-light': BasketLight,
    'blocked-dark': BlockedDark,
    'blocked-light': BlockedLight,
    'box-dark': BoxDark,
    'box-light': BoxLight,
    'calendar-dark': CalendarDark,
    'calendar-light': CalendarLight,
    'card-amex': CardAmex,
    'card-masetercard': CardMastercard,
    'card-visa': CardVisa,
    'chevron-down-dark': ChevronDownDark,
    'chevron-down-light': ChevronDownLight,
    'chevron-left-dark': ChevronLeftDark,
    'chevron-left-light': ChevronLeftLight,
    'chevron-right-dark': ChevronRightDark,
    'chevron-right-light': ChevronRightLight,
    'chevron-right-secondary': ChevronRightSecondary,
    'circle-cross-dark': CircleCrossDark,
    'circle-cross-light': CircleCrossLight,
    'circle-plus-dark': CirclePlusDark,
    'circle-plus-light': CirclePlusLight,
    'checkmark-thin-dark': CheckmarkThinDark,
    'checkmark-thin-light': CheckmarkThinLight,
    'checkmark-thin-green': CheckmarkThinGreen,
    'checkmark-light': CheckmarkLight,
    'checkmark-dark': CheckmarkDark,
    'clipboard-dark': ClipboardDark,
    'clipboard-light': ClipboardLight,
    'clock-dark': ClockDark,
    'clock-light': ClockLight,
    'cross-light': CrossLight,
    'cross-dark': CrossDark,
    'doctor-dark': DoctorDark,
    'doctor-light': DoctorLight,
    'download-dark': DownloadDark,
    'download-light': DownloadLight,
    'edit-dark': EditDark,
    'edit-light': EditLight,
    'error-status': ErrorStatus,
    'exit-dark': ExitDark,
    'exit-light': ExitLight,
    'eye-closed-dark': EyeClosedDark,
    'eye-closed-light': EyeClosedLight,
    'eye-open-dark': EyeOpenDark,
    'eye-open-light': EyeOpenLight,
    'facebook-info': FacebookInfo,
    'facebook-dark': FacebookDark,
    'facebook-light': FacebookLight,
    'heart-dark': HeartDark,
    'heart-light': HeartLight,
    'help-dark': HelpDark,
    'help-light': HelpLight,
    'home-dark': HomeDark,
    'home-light': HomeLight,
    'info-dark': InfoDark,
    'info-light': InfoLight,
    'info-status': InfoStatus,
    'instagram': Instagram,
    'linkedin-dark': LinkedinDark,
    'linkedin-info': LinkedinInfo,
    'linkedin-light': LinkedinLight,
    'loading': Loading,
    'lock-dark': LockDark,
    'lock-light': LockLight,
    'medical-cross-dark': MedicalCrossDark,
    'medical-cross-light': MedicalCrossLight,
    'medicine-dark': MedicineDark,
    'medicine-light': MedicineLight,
    'menu-dark': MenuDark,
    'menu-light': MenuLight,
    'paper-plane-dark': PaperPlaneDark,
    'paper-plane-light': PaperPlaneLight,
    'payment-methods-dark': PaymentMethodsDark,
    'payment-methods-light': PaymentMethodsLight,
    'phone-dark': PhoneDark,
    'phone-light': PhoneLight,
    'pill-dark': PillDark,
    'pill-light': PillLight,
    'pin-dark': PinDark,
    'pin-light': PinLight,
    'play-dark': PlayDark,
    'play-light': PlayLight,
    'profile-dark': ProfileDark,
    'profile-light': ProfileLight,
    'quote-light': QuoteLight,
    'rosette-dark': RosetteDark,
    'rosette-light': RosetteLight,
    'search-dark': SearchDark,
    'search-light': SearchLight,
    'sorted-default-dark': SortedDefaultDark,
    'sorted-default-light': SortedDefaultLight,
    'sort-dark': SortDark,
    'sort-light': SortLight,
    'speech-dark': SpeechDark,
    'speech-light': SpeechLight,
    'stamp-dark': StampDark,
    'stamp-light': StampLight,
    'star-badge-dark': StarBadgeDark,
    'star-badge-light': StarBadgeLight,
    'star-empty-dark': StarEmptyDark,
    'star-empty-light': StarEmptyLight,
    'star-full-dark': StarFullDark,
    'star-full-light': StarFullLight,
    'star-half-dark': StarHalfDark,
    'star-half-light': StarHalfLight,
    'success-dark': SuccessDark,
    'success-light': SuccessLight,
    'success-status': SuccessStatus,
    'tag-dark': TagDark,
    'tag-light': TagLight,
    'trash-dark': TrashDark,
    'trash-light': TrashLight,
    'truck-dark': TruckDark,
    'truck-light': TruckLight,
    'warning-dark': WarningDark,
    'warning-light': WarningLight,
    'warning-status': WarningStatus,
    'x-info': XInfo,
    'x-dark': XDark,
    'x-light': XLight,
}

type HumanReadableList = Array<{
    title: string;
    value: keyof typeof icons;
}>

const capitaliseFirstLetter = (string: string) => (
    string.charAt(0).toUpperCase() + string.slice(1)
);

export const sanityIconOptions = Object.keys(icons).map((key) => ({
    value: key,
    title: capitaliseFirstLetter(key.replace(/-/g, ' ')),
})) as HumanReadableList;

export const iconSizes = {
    xxsmall: 8,
    xsmall: 12,
    small: 16,
    medium: 24,
    large: 32,
    xlarge: 48,
}
